<script>
export default {
  props: {
    'infoText': String,
    'logo': {
      type: String,
      default: () => require('../assets/check.svg')
    },
    'event': {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    onClose(event, action) {
      this.isVisible = false;
      this.$emit('close');
      if (action === undefined) return;
    },
    onOpen() {
      this.isVisible = true;
    }
  }
}
</script>

<template>
  <div
    id="popup-modal"
    tabindex="-1"
    :class="isVisible ? '' : 'opacity-0 pointer-events-none'"
    class="fixed transition-opacity bg-[#6966666e] top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0  h-[100dvh]"
  >
    <div class="relative shadow-lg left-[0] px-4 md:px-0 sm:left-[calc(50dvw-225px)] top-[30dvh] w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow">
        <button
          type="button"
          @click="onClose"
          class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          data-modal-hide="popup-modal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <!-- <span class="sr-only">Close modal</span> -->
        </button>
        <div class="p-4 md:p-5 text-center">
          <div class="flex mb-5 pt-5">
            <img
              :src="logo"
              alt=""
              class="pr-3 self-start"
              style="width: 45px;"
            >
            <h3
              class="text-lg text-center font-normal text-gray-500"
              v-html="infoText"
            ></h3>
          </div>

          <button
            data-modal-hide="popup-modal"
            type="button"
            @click="onClose"
            class="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
          >Закрити</button>
        </div>
      </div>
    </div>
  </div>

</template>