<script>
import Pagination from '../Pagination.vue';
import refresh from '../../lib/refreshTokin.js';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  components: { Pagination },
  data() {
    return {
      comunities: [
      ],
      records: '',
      pages: '',
      currentPage: '',
      comunity: {},
      titles: [
        '№',
        'Назва',
        'Категория',
        'Адмін центр',
        'Область',
        'Площа',
        'Населення'
      ]
    }
  },
  methods: {
    async getComunity(page = 1) {
      try {
        const response = await fetchWithAuth(`/api/communities/pages=${page}`, {
          method: 'GET',
        });
        if (response.ok) {
          const result = await response.json();
          this.comunities = await result.rows;
          this.records = await result.count;
          this.pages = this.records % 50 === 0 ? parseInt(this.records / 50) : parseInt(this.records / 50) + 1;
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    async onCurenPageClick(value) {
      this.currentPage = value;
      this.getComunity(value);
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getComunity();
    });
  }
}

</script>
<template>
  <div class="wrapper-page min-h-[75dvh] pb-2">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-white bg-gray-400">
          <tr class="">
            <th
              v-for="item of titles"
              :key="item.id"
              scope="col"
              class="px-6 py-4"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="comunity in comunities"
            :key="comunity.id"
            class="cursor-pointer border-b hover:bg-gray-50"
            :class="comunity.id % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
          >
            <td class="px-4 py-3">
              {{ comunity.id }}
            </td>
            <th
              scope="row"
              class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap "
            >
              {{ comunity.name }}
            </th>
            <td class="px-4 py-3">
              {{ comunity.category }}
            </td>
            <td class="px-4 py-3">
              {{ comunity.adminCenter }}
            </td>
            <td class="px-4 py-3">
              {{ comunity.region }}
            </td>
            <td class="px-4 py-3 text-right">
              {{ comunity.area }}
            </td>
            <td class="px-4 py-3 text-right">
              {{ comunity.population }}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <Pagination
      ref="pagination"
      :pages="pages"
      @click="onCurenPageClick"
    />
  </div>

</template>

<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>