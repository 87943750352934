<script>

import Pagination from '../Pagination.vue';
import createLink from '@/lib/createLink.js';
import googleCalendarLink from '@/lib/googleCalendarLink';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import processingTime from '@/lib/processingTime';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { jwtDecode } from 'jwt-decode';
import { PER_PAGE } from '../../../config/config.js';
const per_page = parseInt(PER_PAGE.APPEAL);

export default {
  components: { Pagination },
  data() {
    return {
      appeals: [
      ],
      records: '',
      pages: null,
      region_id: null,
      currentPage: '',
      refreshToken: '',
      timeWorks: '',
      titles: [
        '№',
        'Дата надходження',
        'Ім\'я телефон',
        'Месанжер',
        // 'Опис проблеми',
        'Тип допомоги',
        'Місце перебування',
        'Допомога дитині',
        'Дата/час',
        'Статус',
        'Термін виконання',
      ]
    }
  },
  methods: {
    async getAppeals(page, id) {
      try {

        const response = await fetchWithAuth(`/api/appeals/pages=${page}?region_id=${id}`, {
          method: 'GET',
        });
        if (response.ok) {
          const result = await response.json();
          this.appeals = result.rows;
          this.records = result.count;
          this.pages = this.records % per_page === 0 ? parseInt(this.records / per_page) : parseInt(this.records / per_page) + 1;
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    processing(ms) {
      return processingTime(ms)
    },

    onCuurenPageClick(value) {
      this.currentPage = value;
      this.getAppeals(value, this.region_id)
    },
    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },

    setLink(str, phone, className) {
      return createLink(str, phone, className)
    },
    googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime) {

      const start = startDateTime.replace(/-|:|\.\d+/g, '');
      const end = endDateTime.replace(/-|:|\.\d+/g, '');
      return googleCalendarLink(eventTitle, eventLocation, eventDescription, start, end);
    },
    onOpenById(id) {
      this.$router.push(`/admin/appeals-history/appeal${id}`)
    },
    onOpenForm() {
      this.$router.push('/admin/appeal-add');
    },
    onOpenHyistory(id) {
      this.$router.push(`/admin/appeals-history/appeal/${id}`);
    }
  },
  mounted() {
    const token = sessionStorage.getItem("token");
    this.region_id = jwtDecode(token).regionId;

    this.getAppeals(1, this.region_id);
  },
  watch: {
  }
}

</script>
<template>
  <div>
    <div class="wrapper-page min-h-[71dvh] pb-2">
      <div class="flex justify-end">

      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-[13px] text-left rtl:text-right text-gray-500">
          <thead class="text-white bg-gray-400">
            <tr class="">
              <th
                v-for="item of titles"
                :key="item.id"
                scope="col"
                class="text-center px-3 py-2"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in appeals"
              :key="item.id"
              @click="onOpenHyistory(item.id)"
              class="cursor-pointer border-b hover:bg-gray-50"
              :class="item.status_id == 4 ? 'bg-green-50 px-[-1]' : item.status_id != 2 ?'bg-rose-50 px-[-1]':'bg-sky-50 px-[-1]'"
            >

              <td class="px-2 py-1">
                {{ item.id }}
              </td>
              <td class="text-center px-2 py-1">
                {{ onDateFormate(item.createdAt) }}
                <!-- <p>{{ onTimeFormate(item.createdAt) }}</p> -->
              </td>
              <td class="px-2 py-1">
                <p>{{ item.name }}</p>
                <a
                  class="text-sky-600 hover:underline"
                  :href="`tel:+${item.phone}`"
                >{{ item.phone }}</a>
              </td>
              <td class="px-2 py-1">
                {{ item.communiction }}
              </td>
              <td class="px-2 py-1 max-w-[280px] min-w-[250px]">
                {{ item.assistans_type }}
              </td>

              <td class="px-2 py-1">
                <div
                  v-for="community of item.community"
                  :key="community"
                >
                  <p>{{ community }}</p>
                </div>

              </td>
              <td class="text-center px-2 py-1 max-w-[88px]">
                <input
                  type="checkbox"
                  :checked="item.children_help"
                  class="rounded border-sky-400 text-sky-300 focus:ring-white"
                  disabled
                >
              </td>
              <td
                class="px-2 py-1 max-w-[82px]"
                v-if="new Date(item.date_communication) > new Date()"
              >
                <p>{{ onDateFormate(item.date_communication) }}</p>
                <p class="text-center">{{ onTimeFormate(item.date_communication) }}</p>

              </td>
              <td
                class="px-2 py-1"
                v-else
              >
                <p>{{ onDateFormate(item.date_communication) }}</p>
                <p class="text-center">{{ onTimeFormate(item.date_communication) }}</p>
              </td>

              <td class="px-2 py-1">
                <p>{{ item.status.title }}</p>
              </td>
              <td class="pr-3 py-1 text-right max-w-[95px]">
                {{processing(item.prosessing_time)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      ref="pagination"
      :pages="pages"
      @click="onCuurenPageClick"
    />
  </div>
</template>

<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>