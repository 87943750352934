<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Calendar from '../components/calendar/Calendar.vue';

export default {
  components: { Breadcrumb, Calendar },
};
</script>

<template>
  <div id="calendar">
    <Breadcrumb
      title="Календар"
      link="/admin/calendar"
      className="mb-1"
    />
    <Calendar />
  </div>

</template>