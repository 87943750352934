import { render, staticRenderFns } from "./_messanger.vue?vue&type=template&id=28fd92bb&scoped=true"
import script from "./_messanger.vue?vue&type=script&lang=js"
export * from "./_messanger.vue?vue&type=script&lang=js"
import style0 from "./_messanger.vue?vue&type=style&index=0&id=28fd92bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28fd92bb",
  null
  
)

export default component.exports