<template>
  <div>
    <AppealForm />
  </div>
</template>
<script>
import AppealForm from '../components/appeal/AppealForm.vue'
export default {
  name: 'Appeal',
  components: { AppealForm }

}
</script>