
<script>
import CommunityAll from '../components/communities/CommunityAll.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: { Breadcrumb, CommunityAll }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Регіони/Громади"
      link="/admin/settings/regions"
    />
    <CommunityAll />
  </div>
</template>