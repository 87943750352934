
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
import Messanger from '../appeal/components/_messanger.vue';
import Assistance from '../appeal/components/_assistance.vue';
import DateTime from '../appeal/components/_datetime.vue';
import ModalInfoVue from '../ModalInfo.vue';
import refresh from '@/lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { jwtDecode } from 'jwt-decode';

export default {
  components: { Communities, Messanger, Assistance, DateTime, ModalInfoVue },
  data() {
    return {
      id: '',
      appeal: [],
      name: '',
      last_name: '',
      age: null,
      community_id: '',
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      children_count: 0,
      date_communication: '',
      creator: '',
      editor: '',
      errNumberPhone: false,
      status_id: 1,
      logo: '',
      message: '',
      disabled: false,
      child_help: ''
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeals/${id}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeal = await response.json();
          this.child_help = this.appeal.children_help ? 'Так' : 'Ні';
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    async updateAppeal(id, data) {

      data.editor = jwtDecode(sessionStorage.getItem('token')).name;
      const response = await fetchWithAuth(`/api/appeals/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
      });
      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.logo = require('@/assets/check.svg')
          this.message = 'Замовлення збережено';
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async submit() {

      this.data = {
        name: this.appeal.name,
        last_name: this.appeal.last_name,
        age: this.appeal.age,
        community_id: this.appeal.communityId,
        region_id: this.appeal.region_id,
        phone: this.formatPhoneNumber(this.appeal.phone),
        communiction: this.appeal.communiction,
        assistans_type: this.appeal.assistans_type,
        description: this.appeal.description,
        children_help: this.appeal.children_help,
        children_count: this.appeal.children_count,
        date_communication: this.appeal.date_communication,
        status_id: this.appeal.status_id,
      };

      this.data = Object.fromEntries(
        Object.entries(this.data).filter(([, value]) => value !== '' && value !== null && value !== undefined)
      );

      this.onValidatePhoneNumber(this.appeal.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }
      this.updateAppeal(this.id, this.data);
    },

    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.appeal.name = Capitalize(filteredInput);
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.appeal.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;

      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },

    onValidateAge(age) {
      if (age < 18 || age > 90) {
        this.age = null;
        this.$refs.age.classList.add('err');
      } else {
        this.age = age;
        this.$refs.age.classList.remove('err');
      }
    },

    onMessangerSelected(value) {

      this.appeal.communiction = value;
    },
    onAssistanceSelected(value) {

      this.appeal.assistans_type = value;
    },
    onCommunitySelected(region_id, name, adminCenter, region) {
      this.appeal.community_id = region_id;
      this.appeal.community.name = name;
      this.appeal.community.adminCenter = adminCenter;
      this.appeal.community.region = region;
    },
    onDateTimeSelected(value) {

      this.appeal.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
      if (this.errNumberPhone) return;
      this.$router.push('/admin/appeals');
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    },
    handleChildrenHelpChange() {
      this.appeal.children_help;
      this.child_help = this.appeal.children_help ? 'Так' : 'Ні';
      if (~this.child_help) this.appeal.children_count = 0;
    }
  },
  watch: {
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      const segments = window.location.href.split('/');
      this.id = segments.pop();
      this.getAppeal(this.id);
    })
  }
}
</script>

<template>
  <div class="px-0 lg:px-4">
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      :logo="logo"
      @close="hideModal"
    />
    <div
      class="flex justify-end"
      v-if="appeal.status_id ==1"
    >
      <button
        class="bg-[#4294ff] hover:bg-blue-600 focus:outline-none max-w-[180px] text-[13px] rounded-lg mb-3 px-6 py-2 text-white font-semibold shadow"
        @click.prevent="submit"
      >Зберегти</button>
    </div>
    <div class="wrapper-page">
      <form
        action="#"
        method="POST"
        class="mx-auto mt-3"
      >
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Ім'я
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="appeal.name"
                @keyup="filterInput(appeal.name)"
                placeholder="Ім'я"
                required
                :disabled="appeal.status_id !=1 ? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Призвіще
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="appeal.last_name"
                placeholder="Призвіще"
                :disabled="appeal.status_id !=1 ? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Вік
              </span>
            </label>
            <div class="mt-2.5">
              <input
                @change="onValidateAge(appeal.age)"
                ref="age"
                type="number"
                min="18"
                max="90"
                :disabled="appeal.status_id !=1 ? true : false"
                v-model="appeal.age"
                placeholder="Вік (від 18 до 90)"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Телефон
              </span>
            </label>
            <div class="mt-2.5">
              <input
                v-mask="'##(###)###-##-##'"
                placeholder="Телефон"
                v-model="appeal.phone"
                @blur="onValidatePhoneNumber(appeal.phone)"
                required
                :disabled="appeal.status_id !=1 ? true : false"
                type="tel"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
              />
              <p
                v-if="errNumberPhone"
                class="text-red-500 text-[12px] transition-all duration-200"
              >Помилка в наборі телефона*</p>
            </div>
          </div>

          <div class="">
            <Communities
              v-model="appeal.communityId"
              :readonly="appeal.status_id !=1 ? true : false"
              :disabled="appeal.status_id !=1 ? true : false"
              @selected="onCommunitySelected"
            />
          </div>
          <div>
            <DateTime
              @selected="onDateTimeSelected"
              title="Дата/час зустрічи онлайн"
              placeholder="Оберіть дату та час"
              v-model="appeal.date_communication"
              :disabled="appeal.status_id !=1 ? true : false"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label
              for="community_name"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Громада</label>
            <div class="mt-2.5">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal.community"
                v-model="appeal.community.name"
                id="community_name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="adminCenter"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Адмін центр</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal.community"
                v-model="appeal.community.adminCenter"
                id="adminCenter"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="region"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Область</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal.community"
                v-model="appeal.community.region"
                id="region"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="region"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Звсіб комунікаціі</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal"
                v-model="appeal.communiction"
                id="region"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="region"
              class="block text-sm font-medium leading-6 text-gray-900"
            >З чим потрібна допомога</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal"
                v-model="appeal.assistans_type"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="region"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Допомога дітям</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-if="appeal"
                v-model="child_help"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="col-span-1">
            <Messanger
              @checked="onMessangerSelected"
              :readonly="appeal.status_id !=1 ? true : false"
              :disabled="appeal.status_id !=1 ? true : false"
            />
          </div>
          <div class="col-span-1">
            <Assistance
              @checked="onAssistanceSelected"
              :readonly="appeal.status_id !=1 ? true : false"
              :disabled="appeal.status_id !=1 ? true : false"
            />
          </div>
          <div class="col-span-1 mt-4">
            <div class="border rounded border-stone-200 mt-2 space-y-4 bg-white p-4">
              <div class="flex items-center gap-x-3">
                <input
                  id="no"
                  name="children_help"
                  v-model="appeal.children_help"
                  @change="handleChildrenHelpChange"
                  type="radio"
                  :value="false"
                  :disabled="appeal.status_id != 1"
                  class="cursor-pointer h-5 w-5 rounded-md border-pink-200 text-pink-300 focus:ring-white"
                />
                <label
                  for="no"
                  class="cursor-pointer block text-sm font-medium leading-6 text-gray-900"
                >
                  Ні
                </label>
              </div>
              <div class="flex items-center gap-x-3">
                <input
                  id="yes"
                  name="children_help"
                  v-model="appeal.children_help"
                  @change="handleChildrenHelpChange"
                  type="radio"
                  :value="true"
                  :disabled="appeal.status_id != 1"
                  class="h-5 w-5 cursor-pointer rounded-md border-pink-200 text-pink-300 focus:ring-white"
                />
                <label
                  for="yes"
                  class="cursor-pointer block text-sm font-medium leading-6 text-gray-900"
                >
                  Так
                </label>
              </div>
              <div
                class="max-w-full md:max-w-[110px]"
                v-show="appeal.children_help"
              >
                <label
                  for="children_count"
                  class="cursor-pointer block pb-2 text-sm font-medium leading-6 text-gray-700"
                >
                  Кількість дітей:
                </label>
                <select
                  id="children_count"
                  v-model="appeal.children_count"
                  :disabled="appeal.status_id !=1 ? true : false"
                  class="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option
                    v-for="number in 16"
                    :key="number - 1"
                    :value="number - 1"
                  >
                    {{ number - 1 }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="sm:col-span-3 mt-4">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Опис проблеми
              </span>
            </label>
            <div class="mt-2.5">
              <textarea
                rows="4"
                v-model="appeal.description"
                @keyup="filterText(appeal.description)"
                placeholder=""
                :disabled="appeal.status_id !=1 ? true : false"
                :readonly="appeal.status_id !=1 ? true : false"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>

          </div>

        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
}
.wrapper-page {
  height: 68dvh;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
</style>