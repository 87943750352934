import { jwtDecode } from "jwt-decode";
const user = (to, from, next) => {

  const token = sessionStorage.getItem("token");
  const roles = jwtDecode(token).roles;

  if (!token || !roles) { next("/login"); }
  for (const role of roles) {
    if (role.name == "USER") {
      next();
      return
    }
  }
  next("/admin/denied");
}
export default user;
