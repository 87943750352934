import fetchWithAuth from '@/lib/fetchWithAuth';

const state = {
  user: {},
  userName: '',
  appealsLast: [],
  appeals: [],
  totals: '',
  averageTime: '',
  avgTimeByRegionId: [],
  totalsByRegion: [],
  assistanceType: [],
  totalsAssistanceById: [],
  data: [],
  new_appeal: '',
  completed: '',
  region: [],
  in_work: '',
  buyersData: {
    type: 'line',
    data: {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      datasets: [
        {
          backgroundColor: "rgba(99,179,237,0.4)",
          strokeColor: "#63b3ed",
          pointColor: "#fff",
          pointStrokeColor: "#63b3ed",
          data: [203, 156, 99, 251, 305, 247, 256]
        },
        {
          backgroundColor: "rgba(198,198,198,0.4)",
          strokeColor: "#f7fafc",
          pointColor: "#fff",
          pointStrokeColor: "#f7fafc",
          data: [86, 97, 144, 114, 94, 108, 156]
        }
      ]
    },
    options: {
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      }
    }
  }
};

const getters = {
  appealsLast: (state) => state.appealsLast,
  totals: (state) => state.totals,
  newAppeal: (state) => state.new_appeal,
  completed: (state) => state.completed,
  inWork: (state) => state.in_work,
  assistanceType: (state) => state.assistanceType,
  region: (state) => state.region,
};

const mutations = {
  setAppealsLast(state, appealsLast) {
    state.appealsLast = appealsLast;
    console.log(appealsLast);
  },
  setAppeals(state, appeals) {
    state.appeals = appeals;
  },
  setTotals(state, totals) {
    state.totals = totals;
  },
  setNewAppeal(state, newAppeal) {
    state.new_appeal = newAppeal;
  },
  setCompleted(state, completed) {
    state.completed = completed;
  },
  setInWork(state, inWork) {
    state.in_work = inWork;
  },
  setAverageTime(state, averageTime) {
    state.averageTime = averageTime;
  },
  setAssistanceType(state, assistanceType) {
    state.assistanceType = assistanceType;
  },
  setTotalsAssistanceById(state, totalsAssistanceById) {
    state.totalsAssistanceById = totalsAssistanceById;
  },
  setRegion(state, region) {
    state.region = region;
  },
  setTotalsByRegion(state, totalsByRegion) {
    state.totalsByRegion = totalsByRegion;
  },
  setAssistanceData(state, { labels, totals }) {
    state.buyersData.data.labels = labels;
    state.buyersData.data.datasets[0].data = totals;
  }
};

const actions = {
  async getAppealHistoryByGroup({ commit }) {
    try {
      const response = await fetchWithAuth(`/api/appeal-history/totals`, { method: 'GET' });
      if (response.ok) {
        const appeals = await response.json();
        commit('setAppeals', appeals);
        commit('setTotals', appeals.totals);
        commit('setNewAppeal', appeals.new);
        commit('setCompleted', appeals.completed);
        commit('setInWork', appeals.in_work);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getAppeals({ commit }) {
    try {
      const response = await fetchWithAuth(`/api/appeals/last`, { method: 'GET' });
      if (response.ok) {
        const appealsLast = await response.json();
        commit('setAppeals', appealsLast);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalsByRegion({ commit }, id) {
    try {
      const response = await fetchWithAuth(`/api/appeal-history/totals/region/${id}`, { method: 'GET' });
      if (response.ok) {
        const totalsByRegion = await response.json();
        console.log(totalsByRegion);

        commit('setTotalsByRegion', totalsByRegion);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getAssistance({ commit, dispatch }) {
    try {
      const response = await fetchWithAuth(`/api/assistance`, { method: 'GET' });
      if (response.ok) {
        const assistanceType = await response.json();
        commit('setAssistanceType', assistanceType);

        let totalsAssistance = [];
        for (const assistance of assistanceType) {
          const totals = await dispatch('getTotalsAssistanceById', assistance.id);
          totalsAssistance.push(totals);
        }

        const labels = assistanceType.map(item => `${item.description.split(' ')[0]} ...`);
        commit('setAssistanceData', { labels, totals: totalsAssistance });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalsAssistanceById({ commit }, id) {
    try {
      const response = await fetchWithAuth(`/api/appeal-history/totals/assistance/${id}`, { method: 'GET' });
      if (response.ok) {
        const totalsAssistanceById = await response.json();
        commit('setTotalsAssistanceById', totalsAssistanceById);
        return totalsAssistanceById.totals;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getActiveRegion({ commit }) {
    try {
      const response = await fetchWithAuth(`/api/communities/region/active`, { method: 'POST' });
      if (response.ok) {
        const activeRegion = await response.json();
        commit('setRegion', activeRegion);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  namespaced: true,  // Обязательно для модулей
  state,
  getters,
  mutations,
  actions
};
