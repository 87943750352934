
<script>
import AppealAdd from '../components/appeal/_add.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'appealHistoryEdit',
  components: { Breadcrumb, AppealAdd }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Нове звернення"
      link="#"
      className="mb-0"
    />
    <AppealAdd />
  </div>
</template>