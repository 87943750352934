
<script>
import ListByAppealId from '../components/appealHistory/ListByAppealId.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'AppealsHistorylsList',
  components: { Breadcrumb, ListByAppealId }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Зверненя по виконавцям"
      link="#"
    />
    <ListByAppealId />
  </div>
</template>