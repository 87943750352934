<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import UsersList from '../components/users/UsersList.vue';

export default {
  components: { Breadcrumb, UsersList },
};
</script>

<template>
  <div id="users">
    <Breadcrumb
      title="Користувачи"
      link="/admin/users"
    />
    <UsersList />
  </div>

</template>
