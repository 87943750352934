<script>
import AppealReport from '../components/appeal/AppealReport.vue'
export default {
  components: { AppealReport }
}
</script>

<template>
  <div class="overflow-auto">
    <AppealReport />
  </div>
</template>