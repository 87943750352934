

const createLink = function (str, phone, classname = '') {
  // const classname = '';
  const messangers = [];
  const arr = str.split(', ');

  for (let item of arr) {
    if (item === 'Вайбер') messangers.push(`<a class="${classname}" href="viber://chat?number=${phone}" title="Зробити виклик на ${item}">${item}</a>`);
    else if (item === 'Вотсап') messangers.push(`<a class="${classname}" href="https://wa.me/${phone}" title="Зробити виклик на ${item}">${item}</a>`);
    else if (item === 'Телеграм') messangers.push(`<a class="${classname}" href="https://t.me/+${phone}" title="Зробити виклик на ${item}">${item}</a>`);
    else if (item === 'Телефоном') messangers.push(`<a class="${classname}" href="tel:+${phone}" title="Зробити виклик на телефон">${item}</a>`);
    else messangers.push(`${item}`);
  }
  const result = messangers.join(', ');
  return result;

}
export default createLink;