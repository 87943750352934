
<script>
import AppealHistoryEdit from '../components/appealHistory/_edit.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'appealHistoryEdit',
  components: { Breadcrumb, AppealHistoryEdit }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Звернення"
      link="#"
    />
    <AppealHistoryEdit />
  </div>
</template>