
<script>
import AppealsListByRegion from '../components/appeal/AppealsListByRegion.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'Appeal',
  components: { Breadcrumb, AppealsListByRegion }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Звернення"
      link="#"
    />
    <AppealsListByRegion />
  </div>
</template>