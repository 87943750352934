
const DateFormate = (timestamp) => {
  let date = new Date(timestamp);
  let formattedDate = date.toLocaleDateString('ru-Ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  return formattedDate;
}

const TimeFormate = (timestamp) => {
  let date = new Date(timestamp);
  let formattedTime = date.toLocaleTimeString('ru-Ru', {
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    hour12: false
  });
  return formattedTime;
}

module.exports = {
  DateFormate,
  TimeFormate
}
