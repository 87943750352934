
<script>
import Capitalize from '@/lib/capitalize.js';
import { API } from '../../config/config.js';
import { jwtDecode } from 'jwt-decode';
// import refresh from '../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';


export default {
  data() {
    return {
      text: '',
      time: null,
      switch: true,
      socket: null,
      user: '',
      selectedUser: 0,
      users: [],
      senderId: '',
      senderName: '',
      roomId: '',
      userId: '',
      regionId: '',
      userName: '',
      messages: []
    };
  },
  methods: {
    async getUsers(id) {
      try {
        const response = await fetchWithAuth(`/api/users/region/${id}`);
        if (response.ok) {
          this.users = await response.json();
        }
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    },

    async getMessages() {
      try {
        const response = await fetchWithAuth(`/api/chats/${this.userId}/${this.roomId}`,
          {
            method: 'POST',
          });
        if (response.ok) {
          this.messages = await response.json();
          this.onScroll();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            window.location.assign('/admin/denied');
          } else {
            console.error(res);
          }
        }
      } catch (err) {
        console.error('Error fetching messages:', err);
      }
    },

    async writeMessage(data) {
      try {
        const response = await fetchWithAuth(`/api/chats`, {
          method: 'POST',
          body: JSON.stringify(data),
        });
        if (response.ok) {
          this.message = await response.json();
        }
      } catch (err) {
        console.error('Error writing message:', err);
      }
    },

    async sendMessage(text) {
      if (!text) return;

      const data = {
        text: text,
        senderId: this.senderId,
        senderName: this.senderName,
        toUserId: this.selectedUser,
        roomId: this.roomId
      };

      await this.writeMessage(data);
      await this.getMessages();

      this.text = '';

      await this.setFocus();
      this.onScroll();

      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            type: 'broadcast',
            text: text,
            toUserId: this.selectedUser
          })
        );
      }
    },

    async handleMessage(data) {
      if (data.type === 'message' && data.senderId !== this.userId) {
        await this.getMessages();
      }
    },

    async setFocus() {
      this.$refs.input?.focus();
    },

    onScroll() {
      const el = this.$refs.answer;
      const bottomElement = el[el.length - 1];

      if (bottomElement) {
        setTimeout(() => {
          bottomElement.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    },

    onSelectUser(id) {
      this.getUsers(this.regionId).then(() => {
        this.selectedUser = id;
        console.log('selectedUser', this.selectedUser);
      })


      // this.getMessages();
    },

    onCapitalize(text) {
      return Capitalize(text);
    }
  },
  async mounted() {
    try {
      const token = sessionStorage.getItem('token');
      if (token) {
        const decoded = jwtDecode(token);
        this.userId = decoded.id;
        this.senderId = this.userId;
        this.senderName = decoded.name;
        this.userName = this.senderName;
        this.regionId = decoded.regionId;
        this.roomId = this.regionId;
        await this.getUsers(this.regionId);
      }


      await this.getMessages();

      if (process.env.NODE_ENV === 'production') {
        this.socket = new WebSocket(`wss://${API.HOST}/ws/`);
        // this.socket = new WebSocket(`ws://127.0.0.1:${API.SOCKET_PORT}`);
      } else {
        this.socket = new WebSocket(`ws://${API.HOST}:${API.SOCKET_PORT}`);
      }


      this.socket.addEventListener('open', async () => {
        await fetchWithAuth(`/api/users/online/${this.userId}`, {
          method: 'POST'
        });

        this.socket.send(
          JSON.stringify({
            type: 'register',
            userId: this.userId,
            userName: this.userName
          })
        );
      });

      this.socket.addEventListener('close', async () => {
        await fetchWithAuth(`/api/users/offline/${this.userId}`, {
          method: 'POST'
        });
        this.$refs.input.disabled = true;
        this.$router.push('/admin/home');
      });

      this.socket.addEventListener('message', ({ data }) => {
        try {
          const parsedData = JSON.parse(data);
          this.handleMessage(parsedData);
        } catch (error) {
          console.error('Invalid JSON:', data);
        }
      });

      this.socket.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
      });
    } catch (err) {
      console.error('Initialization error:', err);
    }
  },
};

</script>

<template>
  <div>
    <div
      class="flex flex-wrap cursor-pointer p-2"
      @click="selectedUser=0"
    >
      <div
        v-for="user of users"
        :key="user.id"
        class="rounded-md p-1 border-solid border-2  ease-in duration-150"
        :class="selectedUser === user.id && userId !== user.id ? 'min-w-[55px] border-sky-600' : 'border-[#f9fafb]'"
      >
        <div
          v-if="userId !== user.id"
          class="cursor-pointer"
          @click="onSelectUser(user.id)"
        >
          <div class="flex justify-center relative">
            <img
              src="../assets/user.png"
              height="32"
              width="32"
            >
          </div>
          <div class="flex justify-center text-xs">{{onCapitalize(user.name)}}</div>
          <div
            class="flex justify-center text-[9px]"
            :class="user.isOnline ? 'green':'red'"
          >{{user.isOnline ? 'online' :'offline'}}</div>
        </div>
      </div>
    </div>
    <div class="chat-room">
      <div class="wrapper">
        <div
          class="chat-area side-start"
          ref="answer"
        >
          <div
            v-for="msg of messages"
            :key="msg.id"
            :class="userId === msg.senderId ? 'flex self-end': 'flex self-start'"
          ><strong class="from self-center">
              {{ userId === msg.senderId ? 'Я': onCapitalize(msg.senderName) }}: </strong>
            <div
              :class="msg.toUserId == 0 ? 'flex shadow-lg answer':'flex shadow-lg personal'"
              ref="msg"
            >{{ msg.text }}
            </div>
            <p class="time">{{ new Date(msg.createdAt).toLocaleString() }}</p>
          </div>
        </div>
        <div class="flex md:px-10 md:py-4">
          <textarea
            ref="input"
            rows="1"
            v-model="text"
            class="input-message"
            placeholder="Введіть повідомлення"
            @focus="onScroll()"
          ></textarea>
          <button
            id="submit"
            @click.prevent="sendMessage(text)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.chat-room {
  display: flex;
  flex-direction: column;
  min-width: 576px;
  background-color: #e6ecec;
  padding: 15px;
  border-radius: 5px;
}
.wrapper {
  overflow: hidden;
}
.chat-area {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  overflow-y: auto;
  height: 49dvh;
}
.side-start {
  align-items: self-start;
}
.side-end {
  align-items: self-end;
}
.avatar {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  align-self: center;
  /* margin-bottom: 5px; */
}
.input-message {
  display: flex;
  /* align-items: flex-end; */
  width: 96%;
  margin: 10px 3px 10px;
  height: 35px;
  padding: 5px 10px;
}
.answer {
  border-radius: 10px;
  background-color: #d0e2ef;
  padding: 8px 13px;
  margin: 0.2rem 0.2rem 0.2rem 0.5rem;
  text-align: left;
}
.personal {
  border-radius: 10px;
  background-color: #c6f5f4;
  padding: 8px 13px;
  margin: 0.2rem 0.2rem 0.2rem 0.5rem;
  text-align: left;
}
.personal::before {
  content: '';
  background-color: #c6f5f4;
  display: block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 22px;
  left: -15px;
  transform: skew(-32deg);
}
.answer::before {
  content: '';
  background-color: #d0e2ef;
  display: block;
  height: 10px;
  width: 10px;
  position: relative;
  display: inline-block;
  top: 22px;
  left: -15px;
  transform: skew(-32deg);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
textarea {
  padding: 5px 15px 5px 15px;
}
#submit {
  height: 38px;
  width: 38px;
  align-self: center;
  border-radius: 5px;
  background-image: url('../assets/telega.png');
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}
.time {
  font-size: 11px;
  background-color: transparent;
  position: relative;
  top: 28px;
}
.red {
  color: rgb(252, 3, 3);
}
.green {
  color: green;
}
.from {
  color: #347eb3;
}
@media (min-width: 325px) and (max-width: 576px) {
  .chat-room {
    min-width: 375px;
    max-width: 576px;
  }
}
</style>