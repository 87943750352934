
<script>
import Community from '../components/communities/CommunityList.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: { Breadcrumb, Community }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Громади"
      link="/admin/communities"
    />
    <Community />
  </div>
</template>