
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
import Messanger from '../appeal/components/_messanger.vue';
import Assistance from '../appeal/components/_assistance.vue';
import DateTime from '../appeal/components/_datetime.vue';
import ModalInfoVue from '../ModalInfo.vue';

export default {
  components: { Communities, Messanger, Assistance, DateTime, ModalInfoVue },
  data() {
    return {
      name: '',
      last_name: '',
      age: null,
      community_id: '',
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      children_count: 0,
      date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
      logo: '',
      action: '',
      event: '',
      communityName: '',
      adminCenter: '',
      region: ''
    }
  },
  methods: {
    async submit() {
      // Создаем объект данных
      this.data = {
        name: this.name,
        last_name: this.last_name,
        age: this.age,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction,
        assistans_type: this.assistans_type,
        description: this.description,
        children_help: this.children_help,
        children_count: this.children_count,
        date_communication: this.date_communication,
        status_id: this.status_id,
        creator: this.name
      };

      // Маппинг полей для отображения
      const fieldNamesMap = {
        name: 'Ім\'я',
        community_id: 'Громада',
        phone: 'Телефон',
        communiction: 'Спосіб зв\'язку',
        assistance_type: 'Тип допомоги',
        description: 'Опис проблеми',
        children_help: 'Допомога дітям',
        date_communication: 'Дата/час зустрічі онлайн',
      };

      // Проверка номера телефона
      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Проверка на заполненность полей
      const missingFields = Object.entries(this.data)
        .filter(([key, value]) => (value === undefined || value === '') && fieldNamesMap[key] !== undefined)
        .map(([key]) => fieldNamesMap[key]);

      if (missingFields.length) {
        this.message = `<span class="red">Помилка.</span> Не заповнені наступні поля:<br> ${missingFields.join(', ')}.`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return false;
      }
      //console.log(this.data);


      // Отправка данных
      await this.addAppeal(this.data);
    },
    async addAppeal(data) {
      const response = await fetch(`/api/appeals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.message = 'Замовлення збережено';
          this.logo = require('../../assets/check.svg');
          this.event = true;
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;

      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },
    onValidateAge(age) {
      if (age < 18 || age > 90) {
        this.age = null;
        this.$refs.age.classList.add('err');
      } else {
        this.age = age;
        this.$refs.age.classList.remove('err');
      }
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(region_id, name, adminCenter, region) {
      this.region_id = region_id;
      this.communityName = name;
      this.adminCenter = adminCenter;
      this.region = region;
    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },

    hideListCommunity() {
      this.$refs.community.onClose();
    },
    hideModal(e) {
      if (e)
        this.$router.push('/admin/appeals')
    },
  },
  watch: {
  },
  mounted() {

  }
}
</script>

<template>
  <div class="px-0 lg:px-4">
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
    <div class="flex justify-end">
      <button
        class="bg-[#4294ff] hover:bg-blue-600 focus:outline-none max-w-[180px] text-[13px] rounded-lg mb-3 px-6 py-2 text-white font-semibold shadow"
        @click.prevent="submit"
      >Зберегти</button>
    </div>
    <div class="wrapper-page">
      <form
        action="#"
        method="POST"
        class="mx-auto mt-3"
      >
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Ім'я
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="name"
                @keyup="filterInput(name)"
                placeholder="Ім'я"
                required
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Призвіще
              </span>
            </label>
            <div class="mt-2.5">
              <input
                type="text"
                v-model="last_name"
                placeholder="Призвіще"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Вік
              </span>
            </label>
            <div class="mt-2.5">
              <input
                @change="onValidateAge(age)"
                ref="age"
                type="number"
                min="18"
                max="90"
                v-model="age"
                placeholder="Вік (від 18 до 90)"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Телефон
              </span>
            </label>
            <div class="mt-2.5">
              <input
                v-mask="'38(###)###-##-##'"
                placeholder="Телефон"
                v-model="phone"
                id="phone"
                required
                @blur="onValidatePhoneNumber(phone)"
                type="tel"
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
              />
              <p
                v-if="errNumberPhone"
                class="text-red-500 text-[12px] transition-all duration-200"
              >Помилка в наборі телефона*</p>
            </div>
          </div>
          <div class="">
            <Communities
              v-model="communityId"
              @selected="onCommunitySelected"
            />
          </div>
          <div>
            <DateTime
              @selected="onDateTimeSelected"
              title="Дата/час зустрічи онлайн"
              placeholder="Оберіть дату та час"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3">
          <div class="">
            <label
              for="community_name"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Громада</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-model="communityName"
                id="community_name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="adminCenter"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Адмін центр</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-model="adminCenter"
                id="adminCenter"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div class="">
            <label
              for="region"
              class="block text-sm font-medium leading-6 text-gray-900"
            >Область</label>
            <div class="mt-2">
              <input
                type="text"
                readonly
                disabled
                v-model="region"
                id="region"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div class="col-span-1">
            <Messanger
              @checked="onMessangerSelected"
              title="Засіб комунікаціі"
            />
          </div>
          <div class="col-span-1">
            <Assistance
              @checked="onAssistanceSelected"
              title="Потреба з надання допомоги:"
            />
          </div>
          <div class="col-span-1 mt-4">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Допомога дітям
              </span>
            </label>
            <div class="border rounded border-stone-200 mt-2 space-y-4 bg-white p-4">
              <div class="flex items-center gap-x-3">
                <input
                  id="no"
                  name="children_help"
                  v-model="children_help"
                  type="radio"
                  :value="false"
                  class=" cursor-pointer rounded-md h-5 w-5 border-pink-200 text-pink-300 focus:ring-white"
                >
                <label
                  for="no"
                  class=" cursor-pointer block text-sm font-medium leading-6 text-gray-900"
                >Ні</label>
              </div>
              <div class="flex items-center gap-x-3">
                <input
                  id="yes"
                  name="children_help"
                  v-model="children_help"
                  type="radio"
                  :value="true"
                  class="h-5 w-5 cursor-pointer rounded-md border-pink-200 text-pink-300 focus:ring-white"
                >
                <label
                  for="yes"
                  class="cursor-pointer block text-sm font-medium leading-6 text-gray-900"
                >Так</label>
              </div>
              <div
                class="max-w-full md:max-w-[110px]"
                v-show="children_help"
              >
                <label
                  for="children_count"
                  class="cursor-pointer block pb-2 text-sm font-medium leading-6 text-gray-700"
                >
                  Кількість дітей:
                </label>
                <select
                  id="children_count"
                  v-model="children_count"
                  class="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option
                    v-for="number in 16"
                    :key="number - 1"
                    :value="number - 1"
                  >
                    {{ number - 1 }}
                  </option>
                </select>
              </div>

            </div>
          </div>
          <div class="sm:col-span-3 mt-4">
            <label class="block">
              <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                Опис проблеми
              </span>
            </label>
            <div class="mt-2.5">
              <textarea
                rows="4"
                v-model="description"
                required
                @keyup="filterText(description)"
                placeholder=""
                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
}
.wrapper-page {
  height: 65dvh;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
.err {
  border: 2px solid #f27272;
}
.enough {
  position: absolute;
  font-size: 275px;
  transform: rotate(-45deg);
  left: 8%;
  top: 40dvh;
  opacity: 0.07;
  letter-spacing: 1rem;
  z-index: 1;
  color: #f27272;
  pointer-events: none;
}
</style>