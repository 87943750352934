
let API;
let PER_PAGE;
if (process.env.NODE_ENV === "development") {
  PER_PAGE = { APPEAL: 10 }
  API = {
    PORT: 5000,
    HOST: '127.0.0.1',
    SOCKET_PORT: 5002,
  };
} else {
  PER_PAGE = { APPEAL: 10 }
  API = {
    PORT: 5000,
    HOST: 'devweb.pp.ua',
    SOCKET_PORT: 5002,
  };
}

export { API, PER_PAGE };