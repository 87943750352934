<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Chat from '../components/Chat.vue';

export default {
  components: { Breadcrumb, Chat },
};
</script>

<template>
  <div id="chat">
    <Breadcrumb
      title="Чат"
      link="/admin/chat"
    />
    <Chat />
  </div>

</template>
