<script>
import fetchWithAuth from '@/lib/fetchWithAuth';
export default {
  data() {
    return {
      logs: []
    }
  },
  methods: {
    async readFile() {
      try {

        const response = await fetchWithAuth(`/api/files/stream`, {
          method: 'GET',
        });
        if (response.ok) {
          const logs = await response.text();
          this.logs = logs.split('\n');
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.logContainer;
        container.scrollToBottom;
        container.scrollIntoView({ behavior: 'smooth' });
      });
    }
  },
  async mounted() {
    await this.readFile();
  }
}
</script>

<template>
  <div id="file">
    <!-- <div class="flex justify-end">
      <button
        class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 mb-6 text-white font-semibold shadow"
        @click="readFile(); scrollToBottom()"
      >Update</button>
    </div> -->
    <div
      ref="logContainer"
      class="overflow-auto h-[75dvh] bg-slate-700"
    >
      <pre class="text-[11px] text-white py-4 px-6">{{ logs.join('\n') }}</pre>
    </div>

  </div>

</template>