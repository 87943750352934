<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Admin | Enougt';
        // document.description = to.meta.description || '';
      }
    },
  }
}
</script>
