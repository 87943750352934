<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import File from '../components/File.vue';

export default {
  components: { Breadcrumb, File },
};

</script>

<template>
  <div id="users">
    <Breadcrumb
      title="Лог файл"
      link="/admin/log"
    />
    <File />
  </div>

</template>