
<script>
import AppeaHistorylsList from '../components/appealHistory/ListByUser.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'AppealsHistorylsList',
  components: { Breadcrumb, AppeaHistorylsList }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Історія звернень"
      link="/admin/appeals-history"
    />
    <AppeaHistorylsList />
  </div>
</template>