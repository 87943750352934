import { jwtDecode } from 'jwt-decode';

const refresh = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const decoded = jwtDecode(token);

    if (decoded.exp <= Date.now() / 1000) {

      const response = await fetch(`/api/auth/refresh`, {
        method: 'POST',
      });

      if (response.ok) {
        const data = await response.json();
        return data.token;
      } else {
        const errData = await response.json();
        console.log(errData);
      }
    } else {
      return token;
    }
  } catch (err) {
    // location.assign('/login')
  }
}
export default refresh;