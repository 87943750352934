<script>

import refresh from '../../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { jwtDecode } from 'jwt-decode';

export default {
  data() {
    const today = new Date();
    const currentMonth = today.getMonth(); // Получаем номер текущего месяца (0-11)
    const currentYear = today.getFullYear(); // Текущий год

    const days = [];
    const weekDays = ["Неділя", "Понеділок", "Вівторок", "Середа", "Четверг", "Пятниця", "Субота"];

    weekDays.forEach((day) => {
      days.push({
        fullName: day,
        shortName: day.slice(0, 3),
      });
    });

    return {
      currentMonth,
      currentYear,
      days,
      events: [
        // { id: 1, title: "Meeting", time: "12:00~14:00", date: new Date(currentYear, currentMonth, 2) },
        // { id: 2, title: "Shopping", time: "15:00~16:00", date: new Date(currentYear, currentMonth, 12) },
      ],
      calendar: [],
    };
  },
  methods: {
    generateCalendar(year, month) {
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const lastDateOfMonth = new Date(year, month + 1, 0).getDate();

      const calendar = [];
      let week = [];

      for (let i = 0; i < firstDayOfMonth; i++) {
        week.push({ date: null, events: [] });
      }

      for (let date = 1; date <= lastDateOfMonth; date++) {
        const dayEvents = this.events.filter(
          (event) =>
            event.date.getFullYear() === year &&
            event.date.getMonth() === month &&
            event.date.getDate() === date
        );
        week.push({ date, events: dayEvents });

        if (week.length === 7) {
          calendar.push(week);
          week = [];
        }
      }

      if (week.length > 0) {
        while (week.length < 7) {
          week.push({ date: null, events: [] });
        }
        calendar.push(week);
      }

      return calendar;
    },
    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
      this.calendar = this.generateCalendar(this.currentYear, this.currentMonth);
      this.getEvents();
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
      this.calendar = this.generateCalendar(this.currentYear, this.currentMonth);
      this.getEvents();
    },
    getMonthName(monthIndex) {
      return new Date(this.currentYear, monthIndex).toLocaleString("Uk-uk", { month: "long" });
    },
    addEvent(day) {
      console.log(new Date(this.currentYear, this.currentMonth, day)).toDateString();

    },
    async getEvents() {
      try {
        refresh().then(newToken => {
          sessionStorage.setItem('token', newToken);
        });
        const userId = jwtDecode(sessionStorage.getItem('token')).id;
        const res = await fetchWithAuth(`/api/events/user/${userId}`, {
          method: 'GET',
        });
        if (res.ok) {
          const eventsData = await res.json();
          // Map the API data to match the structure expected by the calendar
          this.events = await eventsData.map(event => ({
            id: event.id,
            title: event.title,
            date: new Date(event.dateTime),
            description: event.description,
            status: event.status,
            user: event.user.name,
            phone: event.appeal.phone,
            time: new Date(event.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          }));
        }
        (async () => {
          const newToken = await refresh();
          sessionStorage.setItem("token", newToken);
        })();
      } catch (err) {
        console.log(err);

      }
    },

  },
  mounted() {

    this.getEvents()
      .then(() => {
        this.calendar = this.generateCalendar(this.currentYear, this.currentMonth);
      })
      .catch((err) => {
        const newToken = refresh();
        sessionStorage.setItem("token", newToken);
        console.log(err);
      })
  },

};
</script>

<template>
  <div class="wrapper-page pb-2 min-h-[75dvh] mx-auto">
    <div class="header flex justify-between border-b p-2">
      <span class="text-lg font-bold text-[#4294ff]">
        {{ getMonthName(currentMonth).toUpperCase() }} {{ currentYear }}
      </span>
      <div class="buttons">
        <button
          class="p-1"
          @click="prevMonth"
        >
          <svg
            width="1em"
            fill="gray"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-arrow-left-circle"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              fill-rule="evenodd"
              d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"
            />
          </svg>
        </button>
        <button
          class="p-1"
          @click="nextMonth"
        >
          <svg
            width="1em"
            fill="gray"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-arrow-right-circle"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="relative overflow-x-auto bg-white rounded shadow">

      <table class="w-full">
        <thead>
          <tr>
            <th
              v-for="(day, index) in days"
              :key="index"
              class="p-2 border-r min-h-20 xl:w-40 lg:w-30 md:w-30 w-20 min-w-20 xl:text-sm text-xs bg-[#f9fafb]"
            >
              <span
                class="xl:block lg:block md:block sm:block hidden text-gray-400"
                :class="day.fullName  == 'Неділя' || day.fullName  == 'Субота'? 'text-red-500' : ''"
              >
                {{ day.fullName }}
              </span>
              <span class="xl:hidden lg:hidden md:hidden sm:hidden block">
                {{ day.shortName }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center h-20"
            v-for="week in calendar"
            :key="week[0]?.date"
          >
            <td
              v-for="day in week"
              :key="day.date"
              :class="new Date(currentYear, currentMonth, day.date).toDateString() == new Date().toDateString() ? 'bg-sky-200':''"
              class="border p-1 min-h-40 xl:w-40 lg:w-30 md:w-30 w-20 min-w-20 overflow-auto transition cursor-pointer duration-500 ease hover:bg-gray-300"
            >
              <div class="flex flex-col min-h-40 mx-auto xl:w-40 lg:w-30 md:w-30 sm:w-full min-w-20 overflow-hidden ">
                <div class="top h-5 w-full hover:text-white">
                  <span
                    class=""
                    :class="new Date(currentYear, currentMonth, day.date).toDateString() == new Date().toDateString() ?'today':'text-gray-500'"
                  >{{ day.date }}</span>

                </div>
                <div
                  class="
                    bottom
                    flex-grow
                    h-30
                    py-1
                    w-full
                    cursor-pointer"
                  @click="addEvent(day.date)"
                >
                  <div
                    v-for="event in day.events"
                    :key="event.id"
                    class="event shadow-lg bg-purple-100 text--gray-500 rounded p-1 mb-4 text-sm"
                    :class="status ? 'line-through': ''"
                  >
                    <p class="text-left">{{ event.title }}</p>
                    <p class="text-xs">{{ `Виконавець - ${event.user}` }}</p>
                    <p class="text-right">
                      <a
                        :href="`tel:+${event.phone }`"
                        class="text-xs text-sky-600 hover:underline"
                      >{{ event.phone }}</a>
                    </p>
                    <p class="text-right text-xs text-gray-400">{{ event.time }}</p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
.today {
  color: white;
  text-shadow: 2px 2px 4px #000000;
  background: none;
}
.today::before {
  content: 'Сьогодня:';
  font-size: 12px;
  padding-right: 10px;
}
</style>
