
<script>
import Regions from '../components/communities/Regions.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  components: { Breadcrumb, Regions }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Regional management"
      link="#"
    />
    <Regions />
  </div>
</template>