<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';

export default {
  props: {
    title: { dataType: String },
    placeholder: { dataType: String },
    disabled: { dataType: Boolean, default: false },
    readonly: { dataType: Boolean, default: false },
    value: { dataType: String }
  },
  components: { DatePicker },
  data() {
    return {
      date: this.value ? new Date(this.value) : null, // Инициализация даты из prop
    };
  },
  watch: {
    // Слежение за изменением prop `value`
    value(newValue) {
      this.date = newValue ? new Date(newValue) : null;
    }
  },
  methods: {
    onSelected(date) {
      this.$emit('selected', date);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    const el = document.querySelector('input.mx-input');
    el.style.fontSize = '14px'

  }
};
</script>

<style>
.today {
  background-color: #f8e3ea;
}
.mx-icon-calendar {
  cursor: pointer;
}
input.mx-input {
  border-radius: 5px !important;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  border-color: #d1d5db !important;
  min-height: 40px;
}
/* Выходные дни - суббота и воскресенье */
.vue2-datepicker__calendar__day.is-saturday,
.vue2-datepicker__calendar__day.is-sunday {
  color: red;
}
</style>
<template>
  <div
    :disabled="disabled"
    class="col-span-1 md:col-span-2 mt-[0.175rem]"
  >
    <legend class="block">
      <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
        {{ title }}
      </span>
    </legend>
    <date-picker
      class="mt-2 block w-full rounded-md px-[1px] py-[1px] text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm md:text-[14px] sm:leading-6"
      v-model="date"
      id="datepicker"
      ref="datepicker"
      type="datetime"
      format="DD.MM.YYYY HH:mm"
      @input="onSelected(date)"
      :placeholder="placeholder"
      :default-value="new Date().setHours(8, 0, 0, 0)"
      :disabled-date="notBeforeToday"
      :disabled="disabled"
    ></date-picker>
  </div>
</template>
