import refresh from './refreshTokin';

const fetchWithAuth = async (url, options = {}) => {
  const token = sessionStorage.getItem('token');

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };

  const finalOptions = { ...defaultOptions, ...options };

  const response = await fetch(url, finalOptions);

  if (response.status === 401 || response.status === 403) {
    const newToken = await refresh();
    sessionStorage.setItem('token', newToken);
    finalOptions.headers['Authorization'] = `Bearer ${newToken}`;
    return fetch(url, finalOptions);
  }

  return response;
}

export default fetchWithAuth;
