import Vue from 'vue';
import Vuex from 'vuex';
import home from './home.js';

Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        home
    },

    state: {
        sideBarOpen: false,
        // user: {}
    },
    getters: {
        sideBarOpen: state => state.sideBarOpen,
        // getUser: state => state.user
    },
    mutations: {
        toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar')
        },
    }
})
