<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import PublicUsers from '../components/users/PublicUserListByRegion.vue';

export default {
  components: { Breadcrumb, PublicUsers },
};
</script>

<template>
  <div id="users">
    <Breadcrumb
      title="Користувачи"
      link="#"
    />
    <PublicUsers />
  </div>

</template>
