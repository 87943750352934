
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../registration/community.vue';
import Messanger from '../appeal/_messanger.vue';
import Assistance from '../appeal/_assistance.vue';
import DateTime from '../appeal/_datatime.vue';
import ModalInfo from '../ModalInfo.vue';

export default {
  components: { Communities, Messanger, Assistance, DateTime, ModalInfo },
  data() {
    return {
      name: '',
      community_id: '',
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
      logo: require('@/assets/error.svg'),
      event: false
    }
  },
  methods: {
    async submit() {
      // Создаем объект данных
      this.data = {
        name: this.name,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction,  // исправлено с communiction
        assistans_type: this.assistans_type,  // исправлено с assistans_type
        description: this.description,
        children_help: this.children_help,
        date_communication: this.date_communication,
        status_id: this.status_id,
        creator: this.name
      };

      // Маппинг полей для отображения
      const fieldNamesMap = {
        name: 'Ім\'я',
        community_id: 'Громада',
        phone: 'Телефон',
        communiction: 'Спосіб зв\'язку',
        assistance_type: 'Тип допомоги',
        description: 'Опис проблеми',
        children_help: 'Допомога дітям',
        date_communication: 'Дата/час зустрічі онлайн',
      };

      // Проверка номера телефона
      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) {
        this.message = '<span class="red">Помилка.</span> Невірний номер телефону';
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return;
      }

      // Проверка на заполненность полей
      const missingFields = Object.entries(this.data)
        .filter(([key, value]) => (value === undefined || value === '') && fieldNamesMap[key] !== undefined)
        .map(([key]) => fieldNamesMap[key]);

      if (missingFields.length) {
        this.message = `<span class="red">Помилка.</span> Не заповнені наступні поля:<br> ${missingFields.join(', ')}.`;
        this.logo = require('@/assets/error.svg');
        this.showModal();
        return false;
      }

      // Отправка данных
      await this.addAppeal(this.data);
    },
    async addAppeal(data) {
      const response = await fetch(`/api/appeals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.logo = require('@/assets/check.svg');
          this.message = 'Ваше повідомлення надіслано\n';
          this.event = true;
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {
      // Обновленное регулярное выражение для проверки полного формата
      // const regex = /^38\(0[3-9]\d\)\d{3}-\d{2}-\d{2}$/;
      const regex = /^38\((039|067|068|096|097|098|050|066|095|099|063|073|077|093|091|092|089|094)\)\d{3}-\d{2}-\d{2}$/;


      // Проверяем строгое соответствие формату
      if (regex.test(phoneNumber)) {
        this.errNumberPhone = false; // Номер корректный
      } else {
        this.errNumberPhone = true; // Номер некорректный
      }

      return this.errNumberPhone;
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(value) {
      this.region_id = value;
    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(event) {
      console.log(event);
      if (event) {
        this.clearForm();
        window.location.assign('https://www.divergentwoman.com/enough');
      }
    },
    clearForm() {
      this.name = '';
      this.phone = '';
      this.communication = null;
      this.assistance_type = null;
      this.description = '';
      this.children_help = false;
      this.date_communication = null;
    },
    clearData() {
      window.location.assign('/');
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    },
    autoResize() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; // Сбросить высоту, чтобы пересчитать ее
      textarea.style.height = textarea.scrollHeight + "px"; // Установить новую высоту
    }
  },
  watch: {
  },
  mounted() {

  }
}
</script>

<template>
  <div class="main px-4 py-12 lg:px-8">

    <div
      class="absolute inset-x-0 top-[-10rem] -z-10 overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
    </div>
    <div class="warning mx-auto max-w-2xl">
      <div class="flex">
        <img
          src="../../assets/logo.webp"
          alt="ENOUGH"
          height="56"
          width="75"
        >
        <h2 class="md:ml-8 self-center text-3xl font-bold text-[#6B7280] sm:text-4xl tracking-widest">Конфіденційна допомога</h2>
      </div>

      <p class="mt-8 leading-8 text-justify"><span class="tracking-wide font-[600]">ВАЖЛИВО!</span> Перед заповненням форми, прочитайте коротку інформацію до неї:</p>
      <ul class="text-justify">
        <li class=" pt-4">1. Ми пропонуємо повну <span class="tracking-wide font-[600]">КОНФІДЕНЦІЙНІСТЬ ЗУСТРІЧЕЙ</span> онлайн. Це означає, що ми не будемо збирати ваші персональні дані, крім засобу зв'язку. Ім'я ви можете змінити.</li>
        <li class=" pt-4">2. Онлайн-зустріч буде відбуватися без ввімкненої камери, або шляхом телефонної розмови у вайбер/вотсап/телеграм або ZOOM.</li>
        <li class=" py-4">3. Телефон ГО Інша Жінка<a
            class="px-2 font-bold text-[#FF66C4] underline"
            href="tel:+380501497063"
          >+38(050)149-70-63</a>(можна писати на<a
            class="pl-2 font-bold text-[#FF66C4] underline"
            href="viber://chat?number=380501497063"
            title="Viber"
          >Вайбер</a>). У нас немає цілодобової лінії підтримки, працюють виключно волонтери на безкоштовній основі, тому, якщо вам не відповіли на дзвінок, чекайте - ми обов'язково зателефонуємо у відповідь</li>
      </ul>
    </div>
    <form
      action="#"
      method="POST"
      class="mx-auto max-w-2xl mt-4 md:mt-0"
    >
      <div class="grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2">
        <div class="col-span-2 relative z-0 w-full mt-4 mb-5 group">
          <input
            type="text"
            name="floating_first_name"
            v-model="name"
            @keyup="filterInput(name)"
            id="floating_first_name"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_first_name"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Ім`я*</label>
        </div>
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="tel"
            v-mask="'38(###)###-##-##'"
            placeholder=" "
            required
            v-model="phone"
            @blur="onValidatePhoneNumber(phone)"
            id="floating_telephone"
            :class="errNumberPhone ?'errNumberPhone':''"
            class="block py-2.5 pl-2 w-full opacity-80 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
          />
          <p
            v-if="errNumberPhone"
            class="errNumberPhone text-[12px] transition-all duration-200"
          >Помилка в наборі телефона*</p>
          <label
            for="floating_telephone"
            class="peer-focus:font-medium opacity-70 text-sm absolute duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Телефон*</label>
        </div>

        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />
        <Messanger
          @checked="onMessangerSelected"
          title="Як з вами краще зв'язатися? (можна обрати декілька варіантів)"
        />
        <Assistance
          @checked="onAssistanceSelected"
          title="З чим потрібна допомога? (можна обрати декілька варіантів)"
        />

        <div class="col-span-2">
          <div class="mt-2.5">
            <textarea
              rows="1"
              v-model="description"
              required
              @keyup="filterText(description)"
              @input="autoResize"
              placeholder="Опишіть вашу проблему*"
              class="block w-full border-0 border-gray-300 px-0 py-2 leading-6"
              ref="textarea"
            />
          </div>
        </div>
        <div class="col-span-2 mt-8">
          <legend class="block">
            <div class="after:content-['*'] after:ml-0.5 after:text-red-500 block opacity-70">
              Ваші діти потребують допомоги разом з вами?
            </div>
          </legend>
          <div class="border-t-2 border-gray-300 px-0 py-3.5 mt-2 bg-white">
            <div class="flex items-center gap-3 mb-4">
              <input
                id="no"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="false"
                class=" cursor-pointer h-5 w-5 rounded border-pink-200 text-pink-400 focus:ring-white"
              >
              <label
                for="no"
                class=" cursor-pointer block leading-6 text-sm"
              >Ні</label>
            </div>
            <div class="flex items-center gap-3 mb-4">
              <input
                id="yes"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="true"
                class="h-5 w-5 cursor-pointer  rounded border-pink-200 text-pink-400 focus:ring-white"
              >
              <label
                for="yes"
                class="cursor-pointer block leading-6 text-sm"
              >Так</label>
            </div>
          </div>
        </div>

        <DateTime
          @selected="onDateTimeSelected"
          title=""
          placeholder="Коли Вам буде зручно зустрітися онлайн? *"
        />
      </div>
      <div class="grid grid-cols-3 gap-1">
        <div class="mt-8 col-span-1 md:col-span-1 ">
          <button
            type="button"
            @click="clearData"
            class="w-full hover:underline py-2.5 text-left mb-2 opacity-80"
          >Очистити форму</button>
        </div>
        <div class="mt-8 col-span-2 md:col-span-2 ">
          <button
            type="submit"
            @click.prevent="submit"
            class="flex w-full justify-center rounded-md bg-[#ff66c4] p-3 md:p-2 leading-6 text-white shadow-sm hover:bg-[#de8da8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >#Досить!</button>
        </div>
      </div>
    </form>
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event)"
      :logo="logo"
    />
  </div>
</template>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #6b7280;
}
.errNumberPhone {
  color: coral !important;
}
p,
ul > li,
h2,
legend > span,
span {
  opacity: 1;
  color: #6b7280;
}
button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
#community {
  opacity: 0.8 !important;
  color: #6b7280 !important;
  font-size: 16px !important;
}
textarea {
  opacity: 0.8;
  color: #6b7280;
  border-bottom-width: 2px !important;
  border-color: rgb(209 213 219/1);
  overflow-y: hidden; /* Скрыть вертикальную полосу прокрутки */
  resize: none; /* Отключить возможность ручного изменения размера */
}
textarea:focus {
  box-shadow: none;
  border-color: #ff66c4;
}
.main {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  border-radius: 8px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>