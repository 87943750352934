<script>
import Capitalize from '../lib/capitalize.js';
import ModalInfo from './ModalInfo.vue';
import Communities from './registration/community.vue';


export default {
  name: 'RegisterForm',
  components: { Communities, ModalInfo },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      responsability: '',
      password: '',
      repeat_password: '',
      showPassword: false,
      showRepeatPassword: false,
      isActive: false,
      assistance: [],
      regions: [],
      assistance_id: '',
      region_id: '',
      community_id: '',
      message: '',
      logo: require('../assets/check.svg'),
      event: false,
      action: '',
      isVisible: false
    };
  },
  methods: {
    async submit() {
      if (!this.isValid()) return;

      const data = {
        name: this.name,
        email: this.email,
        phone: this.formatPhoneNumber(this.phone),
        responsability: this.responsability,
        password: this.password,
        isActive: false,
        assistance_id: this.assistance_id,
        region_id: this.region_id,
        community_id: this.communityId,
      };
      this.isVisible = true;
      await this.onRegister(data);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleRepeatPasswordVisibility() {
      this.showRepeatPassword = !this.showRepeatPassword;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(event) {
      if (event)
        // window.location.assign('https://t.me/appealProjectBot');
        console.log(event);

    },
    isValid() {
      return this.passwordsMatch() &&
        this.validatePassword(this.password) &&

        this.validatePhoneNumber(this.phone) &&
        this.validateEmail(this.email);
    },

    passwordsMatch() {
      if (this.password !== this.repeat_password) {
        // alert('Паролі не співпадають');
        this.message = 'Паролі не співпадають'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validatePassword(password) {
      // Регулярное выражение с поддержкой кириллицы и длиной от 8 до 10 символов
      const regex = /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-zА-Яа-яЁё\d!@#$%^&*]{8,10}$/u;

      if (!regex.test(password)) {
        this.event = false;
        this.message = 'Пароль має містити від 8 до 10 символів і включати принаймні одну велику літеру (латиниця або кирилиця), одну малу літеру, одну цифру і один спеціальний символ: !@#$%^&*';
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validatePhoneNumber(phone) {
      const regex = /^\d{2}\(0[5-9]\d\)\d{3}-\d{2}-\d{2}$/;
      if (!regex.test(phone)) {
        this.event = false;
        this.message = 'Некоректний номер телефону'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    validateEmail(email) {
      const regex = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
      if (!regex.test(email)) {
        this.event = false;
        this.message = 'Некоректний email'
        this.logo = require('../assets/error.svg');
        this.showModal();
        return false;
      }
      return true;
    },

    async onRegister(data) {
      try {
        const response = await fetch(`/api/auth/registration`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          this.user = await response.json();
          await this.sendMail(data.email, data.name, 'https://t.me/appealProjectBot');
        } else {
          const errorData = await response.json();
          // alert(errorData.message);
          this.event = false;
          this.isVisible = false;
          this.message = errorData.message;
          this.logo = require('../assets/error.svg');
          this.showModal();
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    },

    resetForm() {
      setTimeout(() => {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.responsability = '';
        this.password = '';
        this.repeat_password = '';
        this.assistance_id = '';
        this.region_id = '';
        this.community_id = '';
      }, 5000);
    },

    async getAssistance() {
      try {
        const res = await fetch(`/api/assistance/all`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        if (res.ok) {
          this.assistance = await res.json();
          return this.assistance;
        } else {
          this.event = false;
          this.message = 'Помилка'
          this.logo = require('../assets/error.svg');
          this.showModal();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async sendMail(to, name, url) {
      try {
        const response = await fetch(`/api/email/registration`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ to, name, verificationUrl: url }),
        });
        if (response.ok) {
          this.isVisible = false;
          this.resetForm();
          this.message = 'Інструкція по активаціі направлена на ваш Email\n'
          this.logo = require('../assets/check.svg');
          this.event = false;
          this.showModal();
        }
      } catch (err) {
        this.message = 'Помилка відправки Email\n'
        this.logo = require('../assets/error.svg');
        this.event = false;
        this.showModal();
      }
    },
    filterInput(input) {
      const regex = /^[а-яА-ЯёЁІі\s]+$/;
      this.name = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },

    filterText(input) {
      const regex = /^[!?.,а-яА-ЯёЁІі\s]+$/;
      this.responsability = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },
    formatPhoneNumber(phone) {
      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },
    onCommunitySelected(value) {
      this.region_id = value;
    },
  },
  mounted() { this.filterInput = this.filterInput.bind(this); }
};
</script>

<template>
  <div class="p-3 md:p-10">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto h-[150px] w-auto"
        src="../assets/logo.webp"
        alt="Enough"
      />
      <h2 class="md:mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-600">Реєстрація</h2>
    </div>
    <form
      class="max-w-md mx-auto"
      @submit.prevent="submit"
    >
      <div class="grid md:grid-cols-2 gap-x-2 gap-y-1">
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="floating_first_name"
            v-model="name"
            @keyup="filterInput(name)"
            id="floating_first_name"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_first_name"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Ім`я*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="tel"
            v-mask="'38(###)###-##-##'"
            placeholder=" "
            required
            v-model="phone"
            id="floating_telephone"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
          />
          <label
            for="floating_telephone"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Телефон*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="email"
            name="floating_email"
            v-model="email"
            id="floating_email"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_email"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#ff66c4] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Email*</label>
        </div>
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="responsability"
            v-model="responsability"
            @keyup="filterText(responsability)"
            id="responsability"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="responsability"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Посада*</label>
        </div>
        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <select
            v-model="assistance_id"
            @click="getAssistance"
            @change="getAssistance"
            id="assistance"
            class="cursor-pointer block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
          >
            <option
              selected
              disabled
            >Оберіть питання пілкування</option>
            <option
              v-for="item in assistance"
              :key="item.id"
              :value="item.id"
            >{{ item.description }}</option>
          </select>
          <label
            for="countries"
            class="peer-focus:font-medium absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#ff66c4]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Оберіть питання пілкування</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            :type="showPassword ? 'text' : 'password'"
            name="floating_password"
            v-model="password"
            id="floating_password"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_password"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#ff66c4] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Пароль*</label>
          <!-- Кнопка для показа/скрытия пароля -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            @click="togglePasswordVisibility"
          >
            <i
              :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
              class="text-gray-400"
            ></i>
          </button>
        </div>

        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            :type="showRepeatPassword ? 'text' : 'password'"
            name="repeat_password"
            v-model="repeat_password"
            id="floating_repeat_password"
            class="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#ff66c4] peer"
            placeholder=" "
            required
          />
          <label
            for="floating_repeat_password"
            class="peer-focus:font-medium absolute text-sm text-gray-600 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#ff66c4] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Підтвердіть пароль*</label>
          <!-- Кнопка для показа/скрытия подтверждения пароля -->
          <button
            type="button"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            @click="toggleRepeatPasswordVisibility"
          >
            <i
              :class="showRepeatPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
              class="text-gray-400"
            ></i>
          </button>
        </div>
        <div class="col-span-2 relative w-full pt-6 group">
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-[#ff66c4] px-3 p-3 md:p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#ff07a0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >Зареєструватися</button>
        </div>
      </div>
    </form>
    <dir
      v-if="isVisible"
      class="fixed transition-opacity bg-[#6966666e] top-[-15px] right-0 left-0 justify-center items-center w-full  h-[100dvh]"
    ></dir>
    <span
      v-if="isVisible"
      class="loader z-50"
    ></span>
    <ModalInfo
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
  </div>
</template>

<style scoped>
label {
  opacity: 0.6;
}
input {
  font-size: 14px !important;
}
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: absolute;
  bottom: 50dvh;
  left: 49dvw;
  background: #9a9898;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #ff3d00, 24px 0 #fff;
  }
  66% {
    background: #ff3d00;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #ff3d00;
  }
}
</style>
