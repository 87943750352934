
<script>
import AppealsList from '../components/appeal/AppealsList.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'Appeal',
  components: { Breadcrumb, AppealsList }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Звернення"
      link="/admin/appeals"
      className="mb-0"
    />
    <AppealsList />
  </div>
</template>