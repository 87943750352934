<script>
import refresh from '../../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js'
import { jwtDecode } from 'jwt-decode';

export default {
  data() {
    return {
      users: [
      ],
      user: {},
      titles: [
        '№',
        'Ім\'я',
        'Телефон',
        'Email',
        'Посада',
        'Питання пілкування',
        'Область',
        'В роботі',
        'Останнє відування'
      ]
    }
  },
  methods: {
    async getUsers(regionId) {
      try {
        const response = await fetchWithAuth(`/api/users/region/${regionId}`, {
          method: 'GET'
        });
        if (response.ok) {
          this.users = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        this.$router.push('/admin/not-found');
      }
    },
    dateFormate(date) {
      if (date) return DateFormate(date);
    },
    timeFormate(date) {
      if (date) return TimeFormate(date);
    }
  },
  mounted() {
    const regionId = jwtDecode(sessionStorage.getItem('token')).regionId;
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getUsers(regionId);
    });
  }
}
</script>

<template>
  <div class="wrapper-page min-h-[75dvh] pb-2">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-[13px] text-left rtl:text-right text-gray-500">
        <thead
          dir="rtl"
          class="text-white bg-gray-400"
        >
          <tr>
            <th
              v-for="(item, id) in titles"
              :key="id"
              class="p-3"
              :class="id == 6 || id == 8 ? 'text-right':id == 7?'text-center':''"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="cursor-pointer border-b bg-white hover:bg-gray-50"
          >
            <td class="px-2 py-1">
              {{ user.id }}
            </td>
            <td class="px-2 py-1">
              {{ user.name }}
            </td>
            <td class="px-2 py-1">
              <a
                class=" text-sky-600 hover:underline"
                :href="`tel:+${user.phone}`"
              >{{ user.phone }}</a>
            </td>
            <td class="px-2 py-1">
              <a
                class=" text-sky-600 hover:underline"
                :href="`mailto:${user.email}`"
              >{{ user.email }}</a>
            </td>
            <td class="px-2 py-1">
              {{ user.responsability }}
            </td>
            <td class="px-2 py-1">
              {{ user.AssistanceTypes ? user.AssistanceTypes.description : 'Не має'}}
            </td>
            <td class="text-right px-2 py-1">
              <p>{{ user.community ?user.community.region :''}}</p>
              <p>{{ user.community ?user.community.adminCenter :''}}</p>
            </td>
            <td class="text-center px-2 py-1">
              <input
                type="checkbox"
                disabled
                :checked="user.workStatus"
                v-model="user.workStatus"
                class="cursor-pointer rounded border-sky-400 text-sky-300 focus:ring-white"
              >
            </td>

            <td class="px-2 py-1 text-right text-xs">
              <p>{{ dateFormate(user.updatedAt) }}</p>
              <p>{{ timeFormate(user.updatedAt) }}</p>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>