<script>
import refresh from '../../lib/refreshTokin';
import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js'

export default {
  data() {
    return {
      users: [
      ],
      user: {},
      titles: [
        '№',
        'Ім\'я',
        'Роль',
        'Телефон',
        'Email',
        'Посада',
        'Питання пілкування',
        'Область',
        'Актв',
        'Останній вхід',
        'Редагувати'
      ]
    }
  },
  methods: {
    async getUsers() {
      try {
        const response = await fetchWithAuth(`/api/users/all`, {
          method: 'GET'
        });
        if (response.ok) {
          this.users = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login');
      }
    },
    async onActivate(id, isActive) {
      const newToken = await refresh();
      sessionStorage.setItem("token", newToken);

      const result = confirm('Ви впевнені що хочите змінити статус користувача?');
      if (!result) {
        this.isActive = !!this.isActive;
        await this.getUsers();
        return;
      }
      try {
        const response = await fetchWithAuth(`/api/users/${id}`, {
          method: 'PUT',
          body: JSON.stringify({ isActive: isActive }),
        });
        if (response.ok) this.appeals = await response.json();
        else console.log(await response.json());

      } catch (err) {
        console.log(err);
      }

    },
    dateFormate(date) {
      if (date) return DateFormate(date);
    },
    timeFormate(date) {
      if (date) return TimeFormate(date);
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getUsers();
    });
  }
}
</script>

<template>
  <div class="wrapper-page min-h-[75dvh] pb-2">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-[13px] text-left rtl:text-right text-gray-500">
        <thead class="text-white bg-gray-400">
          <tr>
            <th
              v-for="(item, id) in titles"
              :key="id"
              class="px-2 py-3"
              :class="id == 7 || id == 9 ? 'text-right':''"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="cursor-pointer border-b hover:bg-gray-50"
            :class="user.isActive ? 'bg-white' : 'bg-rose-50'"
          >
            <td class="px-1 py-2">
              {{ user.id }}
            </td>
            <td class="px-1 py-2">
              {{ user.name }}
            </td>
            <td class="text-[12px] text-center px-1 py-2">
              <p
                v-for="role in user.roles"
                :key="role.id"
              >
                {{ role.name}}
              </p>
            </td>
            <td class="px-1 py-2">
              <a
                class=" text-sky-600 hover:underline"
                :href="`tel:+${user.phone}`"
              >{{ user.phone }}</a>
            </td>
            <td class="px-1 py-2">
              <a
                class=" text-sky-600 hover:underline"
                :href="`mailto:${user.email}`"
              >{{ user.email }}</a>
            </td>
            <td class="px-1 py-2">
              <a class="px-1 py-2">{{ user.responsability }}</a>
            </td>
            <td class="px-1 py-2 min-w-[160px]">
              {{ user.AssistanceTypes ? user.AssistanceTypes.description : 'Не має'}}
            </td>
            <td class="text-right px-1 py-2">
              <p>{{ user.community ?user.community.region :''}}</p>
              <p>{{ user.community ?user.community.adminCenter :''}}</p>
            </td>
            <td class="text-center px-1 py-2">
              <input
                type="checkbox"
                :checked="user.isActive"
                v-model="user.isActive"
                class="cursor-pointer rounded border-sky-400 text-sky-300 focus:ring-white"
                @change="onActivate(user.id, user.isActive)"
              >
            </td>
            <td class="px-1 py-2 text-right text-xs">
              {{ dateFormate(user.updatedAt) }} {{ timeFormate(user.updatedAt) }}
            </td>
            <td class="px-1 py-2">
              <p class="flex justify-end"><router-link
                  to="#"
                  class="text-white bg-[#1da1f2] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                ><svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg></router-link></p>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
</style>