<script>

import fetchWithAuth from '@/lib/fetchWithAuth';
import { DateFormate, TimeFormate } from '@/lib/dateTime';
import { jwtDecode } from 'jwt-decode';
import ModalInfoVue from '../ModalInfo.vue';

export default {
  components: { ModalInfoVue },
  data() {
    return {
      appealId: '',
      appealStatusId: '',
      result: [],
      users: [],
      statuses: [],
      disabled: false,
      message: '',
      logo: '',
      action: '',
      event: '',
    }
  },
  methods: {
    async getAppeal(id) {
      try {
        const response = await fetchWithAuth(`/api/appeal-history/${id}`, {
          method: 'GET'
        });
        if (response.ok) {
          this.result = await response.json();
          this.appealStatusId = this.result.appealStatus.id;
          const id = jwtDecode(sessionStorage.getItem('token')).id;

          if (this.appealStatusId === 4 || id != this.result.userId) this.disabled = true;
          await this.getUsers(this.result.appeal.community.region_id)
          await this.getStatus();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);

      }
    },
    async getUsers(id) {
      try {
        const response = await fetchWithAuth(`/api/users/region/${id}`, {
          method: 'GET'
        });
        if (response.ok) {
          this.users = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStatus() {
      try {
        const response = await fetchWithAuth(`/api/appeal-status`, {
          method: 'GET'
        });
        if (response.ok) {
          this.statuses = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403)
            this.$router.push('/admin/denied');
          if (res.statusCode === 404)
            this.$router.push('/admin/not-found');
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateAppeal(id) {
      const data = {
        userId: this.result.userId,
        appealStatusId: this.result.appealStatus.id,
        comment: this.result.comment
      };
      try {
        const response = await fetchWithAuth(`/api/appeal-history/${id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
        });
        if (response.ok) {
          // alert('Запис успішно оновлен')
          this.message = 'Запис успішно оновлен';
          this.logo = require('../../assets/check.svg');
          this.event = true;
          this.showModal();
        } else {
          const res = await response.json();
          // alert(res.message);
          this.message = res.message;
          this.logo = require('../../assets/error.svg');
          this.event = false;
          this.showModal();
        }
      } catch (err) {
        console.log(err);
      }
    },
    toDate(date) {
      return DateFormate(date);
    },
    toTime(date) {
      return TimeFormate(date);
    },
    auto_grow(ref) {
      const element = this.$refs[ref];
      if (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
      }

    },
    onChageUser(id) {
      const user_id = jwtDecode(sessionStorage.getItem('token')).id
      if (user_id != id) {
        this.result.appealStatus.id = 3
      } else {
        this.result.appealStatus.id = this.appealStatusId;
      }
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal(e) {
      if (e)
        this.$router.push('/admin/appeals-history')
    },
  },
  async mounted() {

    this.appealId = location.pathname.split('/').pop();
    this.getAppeal(this.appealId).then(() => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    });

    window.addEventListener('resize', () => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    });
    window.addEventListener('input', () => {
      this.auto_grow('autoGrowTextArea');
      this.auto_grow('autoGrowTextReport');
    })
  }
}
</script>

<template>
  <div class="border-b border-gray-900/10 pb-12">
    <div
      class="flex justify-end"
      v-if="!disabled"
    >
      <button
        type="button"
        v-if="result"
        @click="updateAppeal(result.id)"
        class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
      >
        Зберегти
      </button>
    </div>
    <div class="mt-2 mx-auto max-w-full md:max-w-[70dvw] grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

      <div class="sm:col-span-3">
        <label
          for="country"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Перенаправити</label>
        <div class="mt-2">
          <select
            id="user"
            v-if="result"
            @change="onChageUser(result.userId)"
            v-model="result.userId"
            :disabled="disabled"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option
              v-for="user of users"
              :key="user.id"
              :value="user.id"
            >{{ user.name }}</option>
          </select>
        </div>
      </div>
      <div class="sm:col-span-3">
        <label
          for="status"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Змінити статус</label>
        <div class="mt-2">
          <select
            id="status"
            :disabled="disabled"
            v-if="result.appealStatus"
            v-model="result.appealStatus.id"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option
              v-for="status of statuses"
              :key="status.id"
              :disabled="status.id < result.appealStatus.id || status.id ===3"
              :value="status.id"
            >{{ status.title }}</option>
          </select>
        </div>
      </div>

      <div class="sm:col-span-3">
        <label
          for="first-name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Імʼя заявника</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="first-name"
            v-if="result.appeal"
            v-model="result.appeal.name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label
          for="assistance"
          class="block text-sm font-medium leading-6 text-gray-900"
        >З чим потрібна допомога</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            id="assistance"
            v-if="result.AssistanceTypes"
            v-model="result.AssistanceTypes.description"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="sm:col-span-2 sm:col-start-1">
        <label
          for="phone"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Телефон</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.phone"
            id="phone"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label
          for="communiction"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Месанжер спілкування</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.communiction"
            id="communiction"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label
          for="date_communication"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Дата/час зустрічи онлайн</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-show="false"
            v-if="result.appeal"
            v-model="result.appeal.date_communication"
            id="date_communication"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <input
            v-if="result.appeal"
            type="text"
            readonly
            disabled
            :value="`${toDate(result.appeal.date_communication)} ${toTime(result.appeal.date_communication)}`"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          for="community_name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Громада</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.name"
            id="community_name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          for="adminCenter"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Адмін центр</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.adminCenter"
            id="adminCenter"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <label
          for="region"
          class="block text-sm font-medium leading-6 text-gray-900"
        >Область</label>
        <div class="mt-2">
          <input
            type="text"
            readonly
            disabled
            v-if="result.appeal"
            v-model="result.appeal.community.region"
            id="region"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div class="sm:col-span-full">
        <label
          for="description"
          class="mb-2 block text-sm font-medium leading-6 text-gray-900"
        > Опис проблеми</label>
        <textarea
          v-if="result.appeal"
          readonly
          disabled
          ref="autoGrowTextArea"
          v-model="result.appeal.description"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ></textarea>
      </div>

      <div class="sm:col-span-full">
        <label class="mb-2 block text-sm font-medium leading-6 text-gray-900">Важлива інформація:</label>
        <textarea
          placeholder="Важлива інформація"
          :disabled="disabled"
          v-model="result.comment"
          ref="autoGrowTextReport"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ></textarea>
      </div>
    </div>
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      @close="hideModal(event, action)"
      :logo="logo"
    />
  </div>
</template>
<style scoped>
textarea {
  resize: none;
  overflow: hidden;
  padding: 10px;
}
label {
  opacity: 0.8;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #6b7280;
}
</style>

