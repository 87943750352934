
import refresh from '../lib/refreshTokin';

const auth = (to, from, next) => {

  try {
    (async () => {
      const newToken = await refresh();
      sessionStorage.setItem("token", newToken);
      next();
    })();
  } catch (err) {
    console.error("Error token:", err);
    sessionStorage.removeItem("token");
    next("/login");
  }
};

export default auth;
