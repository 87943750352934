<script>
import refresh from '../../lib/refreshTokin.js';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  data() {
    return {
      regions: [],
      titles: [
        'ID',
        'Область',
        'Статус',
      ],
      active: ''
    }
  },
  async mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getRegions();
    });
  },
  methods: {
    async getRegions() {
      try {
        const response = await fetchWithAuth(`/api/communities/regions`, {
          method: 'GET',
        });
        if (response.ok) this.regions = await response.json();
        else if (response.statusCode === 403) this.$router.push('/admin/denied');
        else if (response.statusCode === 404) this.$router.push('/admin/not-found');
        else this.$router.push('/login');
      } catch (err) {
        console.error(err);
      }
    },
    async updateActiveStatus(region_id, status) {
      try {
        const response = await fetchWithAuth(`/api/communities/regions/${region_id}`, {
          method: 'PUT',
          body: JSON.stringify({ "activeStatus": status }),
        });
        if (response.ok) this.regions = await response.json();
        else if (response.statusCode === 403) this.$router.push('/admin/denied');
        else if (response.statusCode === 404) this.$router.push('/admin/not-found');
        // else this.$router.push('/login');
      } catch (err) {
        console.error(err);
      }
    },
    async onActivate(region_id, status) {
      const result = confirm('Ви впевнені що хочите змінити статус регіона?');

      if (!result) {
        this.active = !!this.active;
        await this.getRegions();
        return;
      }
      else
        await this.updateActiveStatus(region_id, status);

    }
  }
}
</script>

<template>
  <div class="wrapper-page min-h-[75dvh] pb-2">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-white bg-gray-400">
          <tr>
            <th
              v-for="(item, id) in titles"
              :key="id"
              class="px-2 py-3 text-left"
              :class="id == 2 || id == 0 ? 'text-center':''"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="region in regions"
            :key="region.id"
            class="cursor-pointer border-b hover:bg-gray-50"
            :class="region.active ? 'bg-white text-green-600' : 'bg-rose-50 text-rose-900'"
          >
            <td class="px-1 py-2 text-center">
              {{ region.id }}
            </td>
            <td class="px-1 py-2">
              {{ `${region.region} область` }}
            </td>
            <td class="text-center px-1 py-2">
              <input
                type="checkbox"
                :checked="region.active"
                v-model="region.active"
                class="cursor-pointer rounded border-sky-400 text-sky-300 focus:ring-white"
                @change="onActivate(region.id, region.active)"
              >
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  height: 70dvh;
}
</style>