<template>
  <div id="login">
    <LoginForm v-if="!isAuthenticated" />
    <div v-else>
      <!-- <button @click="accessProtectedRoute">Access Protected Route</button>
      <button @click="logout">Logout</button> -->
      <div id="response">{{ responseMessage }}</div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data() {
    return {
      isAuthenticated: false,
      responseMessage: ''
    };
  },
  methods: {
  },
  mounted() {

  }
};
</script>
