

function formatDateTimeForGoogleCalendar(dateTime) {
  return new Date(dateTime);
}

let eventTitle = "Название события";
let eventLocation = "Адрес места";
let eventDescription = "Описание события";
let startDateTime = formatDateTimeForGoogleCalendar(startDateTime);
let endDateTime = formatDateTimeForGoogleCalendar(endDateTime);

const googleLink = function (eventTitle, eventLocation, eventDescription, startDate, endDate) {
  const baseUrl = 'https://www.google.com/calendar/render?action=TEMPLATE';
  let title = `&text=${encodeURIComponent(eventTitle)}`;
  let location = `&location=${encodeURIComponent(eventLocation)}`;
  let details = `&details=${encodeURIComponent(eventDescription)}`;
  let startTime = `&dates=${startDate}/${endDate}`;

  return `${baseUrl}${title}${location}${details}${startTime}`;
}
googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime);

export default googleLink;

